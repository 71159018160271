import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// import Marquee from "react-easy-marquee";
import Marquee from "react-fast-marquee";
function CaseStudySliderMenu() {


  return (
    <ul>
      <Marquee pauseOnHover={true} direction="right" speed={100} gradient={true}>
        <li>
          <AnchorLink to="/case-study#yahoospreets">
            Yahoo Spreets
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#pocketsport">
            Pocket Sport
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#smartqed">
            Smartqed
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#keepitcleaner">
            Keep it cleaner
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#betterclinics">
            Better Clinics
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#plenty">
            Plenty
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#thepodcastnetwork">
            The Podcast Network
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#kingcontent">
            King content
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#drvoip">
            DrVoip
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#datafree">
            Datafree
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
        <li>
          <AnchorLink to="/case-study#pleezpay">
            PleezPay
            <span className="circles">
              <StaticImage placeholder="none" alt="casestudy" src="../images/arrow-bg.svg" />
            </span>
          </AnchorLink>
        </li>
      </Marquee>
      {/* <Marquee duration={25000} pauseOnHover={true}>
       
      </Marquee> */}
    </ul>
  );
}

export default CaseStudySliderMenu;
