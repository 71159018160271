import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ServiceSlider from "../components/service-slider";
import ReviewsSlider from "../components/reviews-slider";
import ToolsLtr from "../components/tools-ltr";
import ToolsRtl from "../components/tools-rtl";
import { StaticImage } from "gatsby-plugin-image";
import "aos/dist/aos.css";
import { Contact, Popup } from "../components/popup";
import CaseStudySliderMenu from "../components/case-study-slider-menu";
import CustomGraph from "../components/custom-graph";
import CustomGraphResponsive from "../components/custom-graph-responsive";
import CustomMap from "../components/custom-map";
import CustomMarquee from "../components/custom-marquee";
import VideoPlayer from "react-background-video-player";
import hero from "../video/hero.mp4";
import { isMobile } from "react-device-detect";
import employeeSet1 from "../images/employee-set1.webp"
import employeeSet2 from "../images/employee-set2.webp"
import employeeSet3 from "../images/employee-set3.webp"
import employeeSet4 from "../images/employee-set4.webp"

const IndexPage = () => {
  const pictureArray = [employeeSet1, employeeSet2, employeeSet3, employeeSet4];
  const [urlImage, setUrlImage] = useState(`url(${pictureArray[Math.floor(Math.random() * pictureArray.length)]})`)
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowContact, setPopupShowContact] = useState(false);
  const [componentUpdate, setComponentUpdate] = useState(false);
  const [getInTouchClicked, setGetInTouchClicked] = useState(false);
  const updateComponent = () => {
    setComponentUpdate(!componentUpdate);
  };

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };

  const handlePopupModalContact = () => {
    setGetInTouchClicked(!getInTouchClicked);
    setPopupShowContact(!popupShowContact);
    setGetInTouchClicked(!getInTouchClicked);
  };

  useEffect(() => {

    const interval = setInterval(() => {
      const value = Math.floor(Math.random() * pictureArray.length)
      setUrlImage(`url(${pictureArray[value]})`)
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Layout>
        <div className="wrapper">
          <section className="heroHeader">
            <VideoPlayer
              placeholder="none"
              className="video"
              src={hero}
              autoPlay={true}
              muted={true}
            />

            <div className="container-fluid">
              <div className="heroContent">
                <div className="headTitle">
                  <h1>
                    Code and craft innovation empowering technology for your
                    business
                  </h1>
                  {isMobile ? (
                    <button
                      className="btn heroBtn"
                      onTouchEnd={handlePopupModalContact}
                    >
                      Get in touch
                    </button>
                  ) : (
                    <button
                      className="btn heroBtn"
                      onClick={handlePopupModalContact}
                    >
                      Get in touch
                    </button>
                  )}
                </div>
                <p>
                  Trusted by over 1900+ Startups, SMB and Enterprise companies
                </p>
              </div>
              <div className="heroPartner">
                <ul>
                  <CustomMarquee />
                </ul>
              </div>
            </div>
          </section>
          <section className="ourService">
            <div className="wrapperService">
              <h1>
                <span className="circle"></span>Our services
              </h1>
              <div>
                <ServiceSlider />
              </div>
            </div>
          </section>
          <section className="workSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>How we work
                </h1>
                <div className="row">
                  <div className="graphDesktop">
                    <CustomGraph
                      handleComponentUpdate={updateComponent}
                      status={componentUpdate}
                    />
                  </div>
                  <div className="graphMobile">
                    <CustomGraphResponsive
                      handleComponentUpdate={updateComponent}
                      status={componentUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="casestudySection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Case study
                </h1>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="casestudySlider">
                      <StaticImage
                        placeholder="none"
                        alt="casestudy"
                        src="../images/casestudy/casestudy-01.webp"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="caseStudyContent">
                      <div className="caseStudyHead">
                        <h2>Yahoo Spreets</h2>
                        <span>(acquired by Yahoo for $40 million)</span>
                      </div>
                      <p>
                        Spreets is an ecommerce website featuring a daily deal on
                        the best things to do, see, eat and buy in cities across
                        Australia.
                      </p>

                      <p>
                        Receive day's best deal from a range of group buying sites
                        & view all the deals near you, or explore another city.
                      </p>

                      <p>
                        Xminds built the web and mobile applications from scratch.
                      </p>

                      <p>
                        After 11 months of development Spreets was acquired by
                        Yahoo for $40 million.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row technology-row">
                  <h3>Technology: </h3>
                  <div className="caseStudyTechnology">
                    <span className="label label-default">PHP</span>
                    <span className="label label-default">jQuery</span>
                    <span className="label label-default">MySQL</span>
                    <span className="label label-default">
                      Cross-Platform application
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="caseStudyItem">
                    <CaseStudySliderMenu />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="technologiesSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Tools & Technologies
                </h1>
                <div className="tool-ltr">
                  <ToolsLtr />
                  <ToolsRtl />
                </div>
                <div className="tool-rtl"></div>
              </div>
            </div>
          </section>
          <section className="whomSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>For whom
                </h1>
                <div className="whomContent">
                  <ul>
                    <li>
                      <div className="whomItem">
                        <div className="whomTitle">
                          <h2>For Startups</h2>
                        </div>
                        <div className="whomDirection">
                          <StaticImage
                            placeholder="none"
                            alt="arrow"
                            src="../images/arrow-whom.svg"
                          />
                        </div>
                        <div className="whomDetails">
                          <div
                            className="forStartup"
                          >
                            <div className="whomfor-greater">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>
                              Greater flexibility{" "}
                              <p className="hidden">Greater flexibility</p>
                            </h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="200"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-focus">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>
                              Focus on core business{" "}
                              <p className="hidden">Greater flexibility</p>
                            </h5>
                          </div>
                          <div
                            className="forStartup forStartup3"
                            data-aos-delay="300"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-reduce">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Reduced development time and cost</h5>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="whomItem">
                        <div className="whomTitle">
                          <h2>For SMB</h2>
                        </div>
                        <div className="whomDirection">
                          <StaticImage
                            placeholder="none"
                            alt="whom"
                            src="../images/arrow-whom.svg"
                          />
                        </div>
                        <div className="whomDetails">
                          <div
                            className="forStartup"
                            data-aos-delay="400"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-inhouse">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>In-house Management</h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="500"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-regulation">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Regulation compliance</h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="600"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-technical">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Technical support</h5>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="whomItem">
                        <div className="whomTitle">
                          <h2>For Enterprise</h2>
                        </div>
                        <div className="whomDirection">
                          <StaticImage
                            placeholder="none"
                            alt="whom"
                            src="../images/arrow-whom.svg"
                          />
                        </div>
                        <div className="whomDetails">
                          <div
                            className="forStartup"
                            data-aos-delay="700"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-mitigating">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Mitigating organizational risk</h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="800"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-certified">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>
                              Certified Engineers{" "}
                              <p className="hidden">Certified Engineers</p>
                            </h5>
                          </div>
                          <div
                            className="forStartup"
                            data-aos-delay="900"
                            data-aos="zoom-in"
                            data-aos-easing="linear"
                            data-aos-duration="500"
                          >
                            <div className="whomfor-experience">
                              <span className="whomfor-1"></span>
                            </div>
                            <h5>Experience in Long-terms projects </h5>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="whoweSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Who we are
                </h1>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="content">
                        <p>
                          Xminds has worked with more than 1900 businesses (and
                          counting) in the past 18 years and we offer our
                          experience with the best expert talent pool in the
                          industry to fulfil all your application development
                          needs.{" "}
                        </p>
                        <p>
                          We deploy best practices, proven development
                          methodologies and time-tested optimum delivery models
                          for developing and building applications on a fixed
                          time, fixed cost basis.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="projectCount">
                        <div className="countone">
                          <h5>18</h5>
                          <p>years in industry</p>
                        </div>
                        <div className="countone">
                          <h5>1900+</h5>
                          <p>clients worldwide</p>
                        </div>
                        <div className="countone">
                          <h5>150+</h5>
                          <p>strong developers</p>
                        </div>
                        <div className="countone">
                          <h5>8-10</h5>
                          <p>weeks with project development</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="avadarItem">
                  <div className="avatar-group">
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-1">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-2">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-3">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-4">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-5">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-6">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-7">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-8">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-9">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-10">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-11">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-12">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-13">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-14">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-15">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-16">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-17">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-18">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-19">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-20">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>
                    <div
                      className="avatar"
                    >
                      <div className="avatar-box avatarBox-21">
                        <span className="avatarbox-cover" style={{ backgroundImage: urlImage, backgroundRepeat: "no-repeat" }}></span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="workSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <div className="journeyContent">
                  <h1>
                    <span className="circle"></span>Long-term journey
                  </h1>
                  <p>
                    We started our journey with the idea of creating innovative
                    products, and we have been following this trend since 2004 and
                    continue to build technological solutions for our customers.
                  </p>
                </div>
                <div className="journeyTrack">
                  <StaticImage
                    placeholder="none"
                    className="desktop-view"
                    alt="journeyTrack"
                    src="../images/long-term-journey.webp"
                  />
                  <StaticImage
                    placeholder="none"
                    className="mobile-view"
                    alt="journeyTrack"
                    src="../images/long-term-journey-mobile.webp"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="testimonialsSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Testimonials
                </h1>
                <div className="testimonialService">
                  <div className="container">
                    <div className="authorRow">
                      <div className="authorBox">
                        <div className="testimonialBoxItem">
                          <div className="testimonialBoxContent">
                            <div className="author-box authorBox-1">
                              <span className="authorbox-cover"></span>
                            </div>
                            <div className="testimonialAuthor">
                              <h5>Charles Jacobson</h5>
                              <span>CTO - Strategy and Content, Isentia</span>
                            </div>
                          </div>
                          <p>
                            “We’ve used Xminds at King Content for the past 4
                            years. During this time they’ve provided skillful
                            developers who communicate well and are a pleasure to
                            deal with. Xminds are also very flexible and ready
                            help no matter how short the time-frame is. They’ve
                            made offshore development easy for King Content and
                            I’ll definitely use them again.”
                          </p>
                        </div>
                      </div>
                      <div className="authorBox">
                        <div className="testimonialBoxItem">
                          <div className="testimonialBoxContent">
                            <div className="author-box authorBox-2">
                              <span className="authorbox-cover"></span>
                            </div>
                            <div className="testimonialAuthor">
                              <h5>Jeremy Samuel</h5>
                            </div>
                          </div>

                          <p>
                            "We used XMinds when we were in a whole world of pain…
                            other developers had let us down badly.XMinds came in
                            , did a qa of the existing code, created a handover
                            and completion plan and then set about getting the job
                            done properly.After our first experience, we were
                            pretty burned.By the time XMinds got done, we were
                            very satisfied.They delivered a great result, were
                            fantastic to work with and provided code that could be
                            easily understood and kept updated.I would definitely
                            recommend them for any complex software development
                            project."
                          </p>
                        </div>
                      </div>
                      <div className="authorBox">
                        <div className="testimonialBoxItem">
                          <div className="testimonialBoxContent">
                            <div className="author-box authorBox-3">
                              <span className="authorbox-cover"></span>
                            </div>
                            <div className="testimonialAuthor">
                              <h5>Christiaan Erik Rijnders</h5>
                            </div>
                          </div>
                          <p>
                            “Xminds did a thorough, creative and professional job
                            on the interface of our Augmented Reality Eyes
                            application for Android. The remote integration with
                            the work of our engineers in Italy was seamless and
                            Xminds delivered exactly on time. We look forward to
                            working with Xminds again.”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="marketplaceSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Our reviews on Marketplaces
                </h1>
                <div className="marketContainer">
                  <div className="marketRow">
                    <div className="marketLeft">
                      <div className="content">
                        <p>
                          We practice what we preach. See what our clients have to say about us
                        </p>
                        <a
                          target="_blank"
                          href="https://clutch.co/profile/xminds-infotech#summary"
                          className="btn"
                          rel="noreferrer"
                        >
                          Read more reviews{" "}
                        </a>
                        <div className="awardBox">
                          <h4>
                            Recognized on Clutch as a Top Web Developer in India
                          </h4>
                          <div className="awardList">
                            <div className="awardListBox"><span className="awards-1"></span></div>
                            <div className="awardListBox"><span className="awards-2"></span></div>
                            <div className="awardListBox">
                              <a
                                target="_blank"
                                href="https://www.goodfirms.co/company/xminds-infotech-pvt-ltd"
                                rel="noreferrer"
                              >
                                <img style={{width: "243px"}} src="https://assets.goodfirms.co/badges/color-badge/app-development.svg" title="Mobile App Development Company" alt="Mobile App Development Company" />
                              </a>
                            </div>
                            <div className="awardListBox"><span className="awards-4"></span></div>
                            <div className="awardListBox"><div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-clutchcompany-id="17416"></div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="marketRight">
                      <div className="projectCounts">
                        <ReviewsSlider />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="contactSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <div className="contactRelative">
                  <div className="contactTitle">
                    <div className="casestudySlider">
                      <h1>
                        <span className="circle"></span>Contact us
                      </h1>
                    </div>
                  </div>
                  <Contact
                    type="static"
                    handleChange={handlePopupModal}
                    key={[popupShow, popupShowContact]}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="ourofficeSection">
            <div className="container-fluid">
              <div className="wrapperService">
                <h1>
                  <span className="circle"></span>Our offices
                </h1>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ourofficeAddress">
                        <div>
                          <h4>Australia Office</h4>
                          <p>
                            Xminds Solutions | Australia, Suite 1203, 23 Hunter
                            Street, Sydney, NSW, 2000.
                          </p>
                        </div>
                        <div>
                          <h4>Europe Office</h4>
                          <p>TACEMA, Via Garibaldi 9/C, 24122 Bergamo – Italy.</p>
                        </div>
                        <div>
                          <h4>Singapore Office</h4>
                          <p>
                            1 Sophia road #05-23, Peace Centre, Singapore 228149.
                          </p>
                        </div>
                        <div>
                          <h4>Canada Office</h4>
                          <p>1160 Tapscott Rd. Scarborough, ON – M1X 1E9.</p>
                        </div>
                        <div>
                          <h4>India Office (HQ)</h4>
                          <p>
                            #T4 & T8, Thejaswini Building, 7th Floor, Phase I,
                            Technopark, Trivandrum, Kerala, India - 695 581.
                          </p>
                        </div>
                        <div>
                          <h4>India Office (BO)</h4>
                          <p>
                            3rd floor, Artech Samrudhi Magnet Vazhuthacaud,
                            Trivandrum, Kerala, India - 695 014.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="locationMap">
                        <CustomMap />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
        <Popup
          show={popupShowContact}
          handleClose={handlePopupModalContact}
          body="contactForm"
        />
      </Layout>
    </>
  );
};
export const Head = () => (
  <Seo
    title="Xminds | Best Software & Mobile app development solutions"
    description="Xminds is a full service software & mobile app development company with offices in Australia, India, Canada, Malaysia, UK, and Singapore and 18 years of experience."
    url="https://www.xminds.com/"
  />
);
export default IndexPage;
